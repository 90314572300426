import React from 'react';

const AppRoute = ({
  component: Component,
  layout: Layout,
}) => {

  Layout = (Layout === undefined) ? props => (<React.Fragment>{props.children}</React.Fragment>) : Layout;

  return (
    <Layout>
      <Component />
    </Layout>
  );
}

export default AppRoute;
