import React from "react";
import Cta from "../components/sections/Cta";
import Team from "../components/sections/Team";

class About extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="section center-content illustration-section-01">
        <Team />
        <div
          style={{
            marginTop: "60px",
          }}
        />
        <Cta split />
      </section>
    );
  }
}

export default About;
