import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import About from './views/About';
import Jobs from './views/Jobs';
import Contact from './views/Contact';

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Routes>
            <Route
              exact
              path="/"
              element={<AppRoute component={Home} layout={LayoutDefault} />}
            />
            <Route
              exact
              path="/about"
              element={<AppRoute component={About} layout={LayoutDefault} />}
            />
            <Route
              exact
              path="/jobs"
              element={<AppRoute component={Jobs} layout={LayoutDefault} />}
            />
            <Route
              exact
              path="/contact"
              element={<AppRoute component={Contact} layout={LayoutDefault} />}
            />
          </Routes>
        )}
      />
    );
  }
}

export default (props) => <App {...props} />;