import React from 'react';
import classNames from 'classnames';
import { Link } from "react-router-dom";

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <Link to="/about">About Us</Link>
        </li>
        <li>
          <a
            href="https://vortex-games.notion.site/Job-Board-c0b091896c5747e7950f2eeb29b2dc89"
            target="_blank"
          >
            Jobs
          </a>
        </li>
        <li>
          <a href="mailto:contact@vortexgames.gg" target="_blank">
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;