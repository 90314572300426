import React from 'react';
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from './App';
import * as serviceWorker from './serviceWorker';

import './assets/scss/style.scss';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Router>
      <App />
    </Router>,
    rootElement
  );
} else {
  render(
    <Router>
      <App />
    </Router>,
    rootElement
  );
}

serviceWorker.register();
