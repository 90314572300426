import React from "react";

class Jobs extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="section center-content illustration-section-01">
        Jobs coming soon!
      </section>
    );
  }
}

export default Jobs;
