import React from "react";

class Contact extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="section center-content illustration-section-01">
        Contact here
      </section>
    );
  }
}

export default Contact;
